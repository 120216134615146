import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useQueryParam, StringParam } from 'use-query-params';

import AppContainerView from '../../components/AppContainerView';
import MobileHeader from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import { images, InterestIngredientData, ToxicityInformationsData } from '../../data';
import * as styles from '../../styles/interestIngredient/interestIngredient.module.css';
import { RootState } from '../../state/rootStore';

interface Props {
  item: {
    icon: string;
    label: string;
    title?: string;
    param: {
      id: number;
    };
  };
  index: number;
  count: number;
}

const Item = ({ item, index, count }: Props) => {
  return (
    <div className={count - 1 !== index ? styles.item + ' ' + styles.border : styles.item}>
      <img className={styles.icon} src={images[item.icon]} />

      <div
        style={{
          flex: 1,
          padding: '18px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {item.title && (
          <span
            style={{
              fontSize: 13,
              color: '#000000',
              fontWeight: 700,
              lineHeight: '19px',
            }}
          >
            {item.title}
          </span>
        )}
        <span style={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: '19px' }}>{item.label}</span>
      </div>
    </div>
  );
};

const InterestIngredient = ({ location }: any) => {
  // const [type] = useQueryParam('type', StringParam)
  const paramsObj = new URLSearchParams(location.search);
  const [type] = useState(paramsObj.get('type'));
  const data = type === 'baby' ? InterestIngredientData : ToxicityInformationsData;

  const appType = useSelector((state: RootState) => state.appType);

  const getBabyTypeTitle = (type?: string | null) => {
    if (!type) return '';
    if (type === 'baby') return appType === 'pet' ? '민감한 반려동물 주의성분' : '영유아 · 임산부 주의성분';
    else {
      return '사용자 · 사용방법에 따른 체크성분';
    }
  };

  return (
    <AppContainerView>
      <MobileTemplate>
        <MobileHeader title={getBabyTypeTitle(type)} />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {data.map((item, index) => {
            return <Item key={item.icon} item={item} index={index} count={data.length} />;
          })}
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

export default InterestIngredient;
